<template>
  <div class="community-home-page" ref="communityHome">
    <div
      class="pannel pannel-four"
      style="min-height: 100%; padding-bottom: 20px"
    >
      <div>
        <div class="pannel-header">
          <div class="pannel-title">优秀设计师</div>
          <div class="pannel-subtitle">选择关注您感兴趣的设计师</div>
        </div>
        <div class="w pannel-content" v-loading="busy">
          <ul
            class="list-content"
            v-infinite-scroll="loadMore"
            :infinite-scroll-disabled="busy"
            :infinite-scroll-distance="240"
          >
            <li
              class="list-item"
              v-for="(item, index) in designerList"
              :key="index"
              @click="$router.push('/community/designer/' + item.id)"
            >
              <el-card class="person-card">
                <div class="title">
                  <div class="title-left">
                    <el-avatar
                      :size="36"
                      :src="item.photo || circleUrl"
                    ></el-avatar>
                    <div class="user">
                      <div class="name">
                        <span>{{ $lodash.phoneNuberConvert(item.nickName) }}</span>
                      </div>
                      <div class="unit" v-if="item.workUnit || item.job">
                        <span>{{ item.workUnit }}</span>
                        <span>{{ item.job }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="title-right">
                    <div class="title-follow">
                      <el-button
                        v-if="!item.follow"
                        class="follow-btn"
                        @click.stop="handleModifyConcern(item)"
                        >
                          <i class="el-icon-plus"></i>
                          <span>关注</span>
                        </el-button
                      >
                      <el-button
                        v-if="item.follow"
                        class="follow-btn follow-btn-plain"
                        @click.stop="handleModifyConcern(item)"
                        >已关注</el-button
                      >
                    </div>

                    <div class="title-location" v-if="item.address">
                      <i class="el-icon-location"></i>
                      <span>{{ item.address }}</span>
                    </div>
                  </div>
                </div>
                <div class="work" v-if="item.resourceInfos">
                  <div
                    class="img-wrapper"
                    v-for="(img, index) in item.resourceInfos"
                    :key="index"
                  >
                    <img :src="img.obsThumbUrl" alt="" />
                  </div>
                </div>
                <div class="work" v-else>
                  <div class="img-wrapper">
                    <img
                      class="img-default"
                      src="../../assets/images/community/no_image.png"
                      alt=""
                    />
                  </div>
                </div>
              </el-card>
            </li>
          </ul>
          <Empty v-if="isEmpty" description="暂无相关设计师"/>
        </div>
      </div>
    </div>
    <c-footer></c-footer>
  </div>
</template>

<script>
import { EventBus } from "@/EventBus";
import CFooter from "@/components/CFooter/index";
export default {
  components: {
    CFooter,
  },
  data() {
    return {
      SUCCESS_CODE: 0,
      circleUrl:
        "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
      designerList: [],
      busy: false,
      pageParams: {
        pageNum: 1,
        pageSize: 20,
        total: 0,
        currentPage: 0,
      },
      userInfo: {
        id: "",
      },
      keywords: "",
      isEmpty: false,
    };
  },
  created() {
    this.keywords = this.$route.query.keywords;
    let userInfo = localStorage.getItem("userInfo");
    if (userInfo) this.userInfo = JSON.parse(userInfo);
    // this.getDeignerList();
  },
  watch: {
    $route: {
      handler(val) {
        this.keywords = val.query.keywords;
        // console.log(this.keywords);
        this.getDeignerList(true);
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {},
  methods: {
    // 获取设计师列表
    getDeignerList(flag) {
      if (flag) {
        this.designerList = [];
        this.pageParams = {
          pageNum: 1,
          pageSize: 20,
          total: 0,
          currentPage: 0,
        };
      }
      if (
        this.pageParams.currentPage * this.pageParams.pageSize >
        this.pageParams.total
      ) {
        // this.$refs.waterfall.waterfallOver();
        return;
      }
      this.busy = true;
      let params = Object.assign({}, this.pageParams);
      params.search = this.keywords;
      this.$http
        .get("/sys-user/comUsers", { params })
        .then((res) => {
          if (res.code == this.SUCCESS_CODE) {
            this.designerList = this.designerList.concat(res.data.records);
            this.pageParams.total = res.data.total;
            this.pageParams.currentPage = res.data.current;
            if (this.designerList && this.designerList.length) {
              this.isEmpty = false;
            } else {
              this.isEmpty = true;
            }
          }
          this.busy = false;
        })
        .catch((err) => {
          this.$message.error("获取数据失败！");
          this.busy = false;
        });
    },
    // 关注/取消关注设计师
    handleModifyConcern(data) {
      if (this.userInfo.id == data.id) {
        return this.$message.warning("您不能关注自己哦！");
      }
      if (data.follow) {
        this.$confirm("此操作取消对该设计师的关注, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: true,
        })
          .then(() => {
            this.modifyConcern(data);
          })
          .catch(() => {});
      } else {
        this.modifyConcern(data);
      }
    },
    modifyConcern(data) {
      let params = {
        id: data.id,
        status: data.follow ? 0 : 1,
      };
      this.$http
        .post("/relation/modify", null, { params })
        .then((res) => {
          if (res.code == this.SUCCESS_CODE) {
            this.$message.success(res.msg);
            // this.getDeignerList();
            data.follow = !data.follow;
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          this.$message.error("操作失败！");
        });
    },
    updataDesignerInfo() {
      this.getDesignerInfo(this.id);
    },
    loadMore() {
      this.pageParams.pageNum++;
      this.getDeignerList();
    },
  },
};
</script>
